import { Serializer } from 'src/app/core/common/http/serializer';
import { Injectable } from '@angular/core';
import { BeneficiarySerializer } from 'src/app/richieste/applicant-data/resource/beneficiary.serializer';
import { BeneficiaryResource } from 'src/app/richieste/applicant-data/resource/beneficiary.resource';
import { SubmitterSerializer } from 'src/app/richieste/resource/submitter.serializer';
import { AssunzioniInfoResource, AssunzioniRichiestaResource, HiringResource, AssignmentResource, EmployeeResource, ApplicantOfferDataResource, CensimentoCandidatoResource, CensimentoNegoziatoResource } from './assunzioni.resource';
import { HiringRequestModel } from '../model/hiring.request.model';
import { TransparentModel } from '../model/transparent.model';

@Injectable({
  providedIn: 'root',
})

export class AssunzioniRichiestaSerializer implements Serializer {

  beneficiarySerializer: BeneficiarySerializer;
  hiringSerializer: HiringSerializer;
  submitterSerializer: SubmitterSerializer;
  raasSerializer: RaasSerializer;
  censusSerializer: CensusSerializer;

  constructor() {
    this.beneficiarySerializer = new BeneficiarySerializer();
    this.hiringSerializer = new HiringSerializer();
    this.raasSerializer = new RaasSerializer();
    this.submitterSerializer = new SubmitterSerializer();
    this.censusSerializer = new CensusSerializer();
  }

  fromJson(json: any): AssunzioniInfoResource {
    let res = null;
    if (json != null) {
      const resourceInfo = new AssunzioniInfoResource();
      let data;
      if (json.request) {
        data = json.request;
      } else if (json.raas) {
        data = json.raas;
      } else if (json.process) {
        data = json.process;
      } else {
        data = json;
      }
      if (json.raas) {
        resourceInfo.raas = this.fromHiringJsonRequest(data);
        res = resourceInfo;
      } else if (json.process) {
        resourceInfo.process = data;
        res = resourceInfo;
      } else if (json.engagement_id) {
        resourceInfo.raas = data;
        res = resourceInfo;
      } else {
        resourceInfo.raas = this.fromJsonRequest(data);
        res = resourceInfo;
      }
    }
    return res;
  }

  fromJsonRequest(json: any): AssunzioniRichiestaResource {

    let resource = new AssunzioniRichiestaResource();
    resource.id = json.id;
    resource.engagement_id = json.engagement_id;
    resource.census_id = json.census_id;
    resource.beneficiary = new Array<BeneficiaryResource>();
    resource.beneficiary.push(this.beneficiarySerializer.fromJson(json.beneficiary));
    if (json.submitter) {
      resource.submitter = this.submitterSerializer.fromJson(json.submitter);
    } else {
      resource.submitter_id = json.submitter_id;
    }
    resource.created_at = json.created_at;
    resource.status = json.status_manage;
    resource.requestReason = json.request_reason;
    resource.title = json.qualification;
    resource.contractType = json.contract_type;
    resource.managementId = json.manage_id;

    return resource;
  }

  fromHiringJsonRequest(json: any): AssunzioniRichiestaResource {

    let resource = new AssunzioniRichiestaResource();

    resource.description_profile = json.description_profile;
    resource.general_information = json.general_information;
    resource.offer = json.offer;
    resource.census_id = json.census_id;
    resource.equipment = json.equipment;
    resource.id = json.id;
    resource.engagement_id = json.engagement_id;
    resource.process_id = (json.process_id!= null && typeof json.process_id != typeof undefined)?json.process_id:"";
    resource.status = json.status;
    resource.beneficiary = new Array<BeneficiaryResource>();
    resource.beneficiary.push(this.beneficiarySerializer.fromJson(json.beneficiary));

    if (json.submitter) {
      resource.submitter = this.submitterSerializer.fromJson(json.submitter);
    } else {
      resource.submitter_id = json.submitter_id;
    }

    return resource;
  }

  toJson(model: HiringRequestModel): any {
    const resource = new AssunzioniInfoResource();
    const assunzioniRichiestaResource = new AssunzioniRichiestaResource();
    assunzioniRichiestaResource.beneficiary = model.beneficiary;
    assunzioniRichiestaResource.submitter = this.submitterSerializer.toJson(model.submitter);
    assunzioniRichiestaResource.general_information = this.raasSerializer.toGeneralJson(model.hiringModel);
    assunzioniRichiestaResource.description_profile = this.raasSerializer.toDescriptionJson(model.hiringModel);
    assunzioniRichiestaResource.offer = this.raasSerializer.toOfferJson(model.hiringModel);
    assunzioniRichiestaResource.equipment = this.raasSerializer.toEquipmentJson(model.hiringModel);
    resource.raas = assunzioniRichiestaResource;
    return resource;
  }

  toCensusJson(model: HiringRequestModel): any {
    const resource = new AssunzioniInfoResource();
    const assunzioniRichiestaResource = new AssunzioniRichiestaResource();
    assunzioniRichiestaResource.submitter = this.submitterSerializer.toJson(model.submitter);
    assunzioniRichiestaResource.general_information = this.raasSerializer.toGeneralJson(model.hiringModel);
    assunzioniRichiestaResource.description_profile = this.raasSerializer.toDescriptionJson(model.hiringModel);
    assunzioniRichiestaResource.offer = this.raasSerializer.toOfferGestioneJson(model.hiringModel);
    assunzioniRichiestaResource.equipment_full = this.raasSerializer.toEquipmentFullJson(model.hiringModel);
    resource.raas = assunzioniRichiestaResource;
    const censimentoCandidatoResource = new CensimentoCandidatoResource();
    censimentoCandidatoResource.candidate_registry = this.censusSerializer.toCandidateJson(model.candidateModel);
    resource.census_candidato = censimentoCandidatoResource;
    const censimentoNegoziatoResource = new CensimentoNegoziatoResource();
    censimentoNegoziatoResource.negotiated = this.censusSerializer.toNegotiateJson(model.negotiateModel);
    resource.census_negoziato = censimentoNegoziatoResource;
    resource.trasparent_selection = this.censusSerializer.toTransparentJson(model.transparentModel);

    return resource;
  }

  toHiringJson(model: HiringRequestModel): any {
    const resource = new AssunzioniInfoResource();
    const assunzioniRichiestaResource = new AssunzioniRichiestaResource();
    assunzioniRichiestaResource.submitter = this.submitterSerializer.toJson(model.submitter);
    assunzioniRichiestaResource.general_information = this.raasSerializer.toGeneralJson(model.hiringModel);
    assunzioniRichiestaResource.description_profile = this.raasSerializer.toDescriptionJson(model.hiringModel);
    assunzioniRichiestaResource.offer = this.raasSerializer.toOfferGestioneJson(model.hiringModel);
    assunzioniRichiestaResource.equipment_full = this.raasSerializer.toEquipmentFullJson(model.hiringModel); //cambiare in _full
    assunzioniRichiestaResource.submitter_superior = model.hiringModel.submitter_superior;
    resource.raas = assunzioniRichiestaResource;
    const censimentoCandidatoResource = new CensimentoCandidatoResource();
    censimentoCandidatoResource.candidate_registry = this.censusSerializer.toCandidateJson(model.candidateModel);
    resource.census_candidato = censimentoCandidatoResource;
    const censimentoNegoziatoResource = new CensimentoNegoziatoResource();
    censimentoNegoziatoResource.negotiated = this.censusSerializer.toNegotiateJson(model.negotiateModel);
    resource.census_negoziato = censimentoNegoziatoResource;
    if(model.transparentModel) {
      resource.trasparent_selection = this.censusSerializer.toTransparentJson(model.transparentModel);
    }

    return resource;
  }
}

export class RaasSerializer {

  TRUE: boolean = true;
  assignmentSerializer: AssignmentSerializer;
  employeeSerializer: EmployeeSerializer;
  applicantOfferDataSerializer: ApplicantOfferDataSerializer;


  constructor() {
    this.employeeSerializer = new EmployeeSerializer();
    this.assignmentSerializer = new AssignmentSerializer();
    this.applicantOfferDataSerializer = new ApplicantOfferDataSerializer();
  }



  toGeneralJson_addToReponse__request_reason(res: any, model: any) {
    if (model.request_reason[0]) { res.request_reason = model.request_reason[0]; }
    return res;
  }

  toGeneralJson_addToReponse__commessa(res: any, model: any) {
    if (model.numberOrSubject) { res.commessa = this.assignmentSerializer.toJson(model); }
    return res;
  }

  toGeneralJson_addToReponse__summary_sheet(res: any, model: any) {
    if (model.summary_sheet) {
      if (model.summary_sheet.sds != null) {
        res.summary_sheet = {
          'link_to_file': null,
          'sds': model.summary_sheet.sds
        }
      } else {
        res.summary_sheet = {
          'link_to_file': model.summary_sheet,
        }
      }
    }
    return res;
  }

  toGeneralJson_addToReponse__employee_breakoff(r: any, model: any) {
    if (model.employee_breakoff) {
      r.employee_breakoff = {
        'name': model.employee_breakoff
      };
    }
    return r;
  }

  toGeneralJson_addToReponse__employee_date_breakoff(res: any, model: any) {
    if (model.employee_date_breakoff) { res.employee_date_breakoff = model.employee_date_breakoff; }
    return res;
  }

  toGeneralJson_addToReponse__other(res: any, model: any) {
    if (model.other) { res.other = model.other; }
    return res;
  }

  toGeneralJson_addToReponse__expect_budget(res: any, model: any) {
    if (model.expect_budget[0]) { res.expect_budget = model.expect_budget[0].value; }
    return res;
  }


  toGeneralJson(model: any): any {
    let res = new HiringResource();
    if (model != null) {
      res = this.toGeneralJson_addToReponse__request_reason(res, model);
      res = this.toGeneralJson_addToReponse__commessa(res, model);
      res = this.toGeneralJson_addToReponse__summary_sheet(res, model);
      res = this.toGeneralJson_addToReponse__employee_breakoff(res, model);
      res = this.toGeneralJson_addToReponse__employee_date_breakoff(res, model);
      res = this.toGeneralJson_addToReponse__other(res, model);
      res = this.toGeneralJson_addToReponse__expect_budget(res, model);
    }
    return res;
  }


  toDescriptionJson_addToResponse__job(res: any, model: any) {
    if (model.job) { res.job = model.job }
    return res;
  }

  toDescriptionJson_addToResponse__refer_to(res: any, model: any) {
    if (model.displayName || model.role) { res.refer_to = this.employeeSerializer.toJson(model); }
    return res;
  }

  toDescriptionJson_addToResponse__head_office(res: any, model: any) {
    if (model.head_office) { res.head_office = model.head_office; }
    return res;
  }

  toDescriptionJson_addToResponse__hypothesized_qualification(res: any, model: any) {
    if (model.hypothesized_qualification) {
      res.hypothesized_qualification = {
        'qualification': model.hypothesized_qualification[0]
      };
    }
    if (model.worker && model.worker.length > 0) {
      res.hypothesized_qualification.qualification_level = model.worker[0];
    }
    if (model.intermediate && model.intermediate.length > 0) {
      res.hypothesized_qualification.qualification_level = model.intermediate[0];
    }
    if (model.officeWorker && model.officeWorker.length > 0) {
      res.hypothesized_qualification.qualification_level = model.officeWorker[0];
    }
    if (model.squareWorker && model.squareWorker.length > 0) {
      res.hypothesized_qualification.qualification_level = model.squareWorker[0];
    }
    return res;
  }

  toDescriptionJson_addToResponse__hypothesized_contract(res: any, model: any) {
    if (model.hypothesized_contract) {
      res.hypothesized_contract = {
        'typology': model.hypothesized_contract[0]
      };
    }
    if (model.subordinateContract && model.subordinateContract[0] && model.subordinateContract[0].description != "") {
      res.hypothesized_contract.subordinated_typology = model.subordinateContract[0];
    }
    if (model.apprenticeshipDuration) {
      res.hypothesized_contract.duration = model.apprenticeshipDuration;
    }
    if (model.administeredContract) {
      res.hypothesized_contract.number_of_month = model.administeredContract;
    }

    if (model.stageContract && model.stageContract.length > 0) {
      res.hypothesized_contract.subordinated_typology = model.stageContract[0];
    }

    if (model.stageContractDuration) {
      res.hypothesized_contract.duration = model.stageContractDuration;
    }
    return res;
  }

  toDescriptionJson_addToResponse__part_time(res: any, model: any) {
    if (model.partTime && model.partTime.length > 0) {
      res.part_time = {
        'choosed': model.partTime[0].value
      };
    }
    if (model.partTime == null || model.partTime == "") {
      res.part_time = {
        'choosed': null
      };
    }
    if (model.partTimeHours) {
      let partTimeHours = res.part_time;
      partTimeHours.time_day_work = model.partTimeHours;
      res.part_time = partTimeHours;
    }
    return res;
  }

  toDescriptionJson_addToResponse__shift_worker(res: any, model: any) {
    res.shift_worker = null;
    if (model.shiftWorker && model.shiftWorker.length > 0) {
      res.shift_worker = model.shiftWorker[0].value;
    }
    return res;
  }

  toDescriptionJson_addToResponse__cost_type(res: any, model: any) {
    if (model.cdcType && model.cdcType.length > 0) { res.cost_type = model.cdcType[0]; }
    return res;
  }

  toDescriptionJson_addToResponse__cdc(res: any, model: any) {
    if (model.cdc) { res.cdc = model.cdc; }
    return res;
  }

  toDescriptionJson_addToResponse__ral(res: any, model: any) {
    if (model.ral) { res.ral = model.ral; }
    return res;
  }

  toDescriptionJson_addToResponse__assumed_start_date(res: any, model: any) {
    if (model.activitiesBeginningDate) { res.assumed_start_date = model.activitiesBeginningDate; }
    return res;
  }

  toDescriptionJson(model: any): any {
    let res = new HiringResource();
    if (model != null) {
      res = this.toDescriptionJson_addToResponse__job(res, model);
      res = this.toDescriptionJson_addToResponse__refer_to(res, model);
      res = this.toDescriptionJson_addToResponse__head_office(res, model);
      res = this.toDescriptionJson_addToResponse__hypothesized_qualification(res, model);
      res = this.toDescriptionJson_addToResponse__hypothesized_contract(res, model);
      res = this.toDescriptionJson_addToResponse__part_time(res, model);
      res = this.toDescriptionJson_addToResponse__shift_worker(res, model);
      res = this.toDescriptionJson_addToResponse__cost_type(res, model);
      res = this.toDescriptionJson_addToResponse__cdc(res, model);
      res = this.toDescriptionJson_addToResponse__ral(res, model);
      res = this.toDescriptionJson_addToResponse__assumed_start_date(res, model);
    }
    return res;
  }

  toOfferJson(model: any): any {
    let res = new HiringResource();
    if (model != null) {
      if (model.applicantOffer) {
        res.candidate_offer = {
          'choosed': model.applicantOffer[0].value
        };
      }
      if (model.applicantOfferData && Object.keys(model.applicantOfferData).length != 0) {
        res.candidate_offer.candidate = {
          'name': model.applicantOfferData
        };
      } else if (model.applicantOffer && model.applicantOffer[0].value == null) {
        res.candidate_offer.candidate = {
          'name': ""
        }
      }

      if (model.applicantOffer && model.applicantOffer[0].value == this.TRUE && model.applicantOfferattachment && Object.keys(model.applicantOfferattachment).length != 0) {
        res.candidate_offer.link_to_cv = model.applicantOfferattachment;
      } else if (model.applicantOffer && model.applicantOffer[0].value == this.TRUE) {
        res.candidate_offer.link_to_cv = {
          id: "",
          name: "",
          mime_type: "",
          web_view_link: ""
        };
      }
    }
    return res;
  }

  toOfferGestioneJson(model: any): any {
    let res = new HiringResource();
    if (model != null) {
      if (model.applicantOffer) {
        res.candidate_offer = {
          'choosed': model.applicantOffer[0].value
        };
      }
      if (model.applicantOfferData) {
        res.candidate_offer.candidate = {
          'name': model.applicantOfferData
        };
      }
      if (model.applicantOffer && model.applicantOffer[0].value == this.TRUE && model.applicantOfferattachment && Object.keys(model.applicantOfferattachment).length != 0) {
        res.candidate_offer.link_to_cv = model.applicantOfferattachment;
      } else {
        if (model.applicantOffer && model.applicantOffer[0].value == this.TRUE) {
          res.candidate_offer.link_to_cv = {};
          res.candidate_offer.link_to_cv.id = "";
          res.candidate_offer.link_to_cv.name = "";
          res.candidate_offer.link_to_cv.mime_type = "";
          res.candidate_offer.link_to_cv.web_view_link = "";
        }
      }
    }
    return res;
  }

  toEquipmentJson(model: any): any {
    let res = new HiringResource();
    if (model != null && model.car) {
      res.car = {
        'choosed': model.car[0].value
      };
    }
    if (model != null && model.carList && model.carList.length > 0) {
      res.car.type = model.carList[0];
    }
    if (model != null && model.autoBenefitList && model.autoBenefitList.length > 0) {
      res.car.category_car_type = model.autoBenefitList[0].value; //da controllare
    }
    if (model != null && model.telepass && model.telepass[0]) {
      res.telepass = model.telepass[0].value;
    }
    if (model != null && !model.telepass) {
      res.telepass = false;
    }
    if (model != null && model.smartPhone && model.smartPhone[0]) {
      res.smartphone = model.smartPhone[0].value;
    }

    return res;
  }

  toEquipmentFullJson(model: any): any {
    let res = new HiringResource();
    if (model != null && model.car && model.car.length > 0) {
      res.car = {
        'choosed': model.car[0].value
      };
    }
    if (model != null && model.carList && model.carList.length > 0) {
      res.car.type = model.carList[0];
    }
    if (model != null && model.autoBenefitList && model.autoBenefitList.length > 0) {
      res.car.category_car_type = model.autoBenefitList[0];
    }
    if (model != null && model.telepass && model.telepass.length > 0) {
      res.telepass = model.telepass[0].value;
    }
    if (model != null && !model.telepass && model.telepass.length > 0) {
      res.telepass = false;
    }
    if (model != null && model.smartPhone && model.smartPhone.length > 0) {
      res.smartphone = model.smartPhone[0].value;
    }

    return res;
  }
}

export class CensusSerializer {

  assignmentSerializer: AssignmentSerializer;
  employeeSerializer: EmployeeSerializer;
  applicantOfferDataSerializer: ApplicantOfferDataSerializer;


  constructor() {
    this.assignmentSerializer = new AssignmentSerializer();
    this.employeeSerializer = new EmployeeSerializer();
    this.applicantOfferDataSerializer = new ApplicantOfferDataSerializer();
  }


  toCandidateJson_addToResponse__last_name(res: any, model: any) {
    if (model.lastName == "" || model.lastName) { res.last_name = model.lastName; }
    return res;
  }

  toCandidateJson_addToResponse__first_name(res: any, model: any) {
    if (model.firstName == "" || model.firstName) { res.first_name = model.firstName; }
    return res;
  }

  toCandidateJson_addToResponse__cf(res: any, model: any) {
    if (model.cf == "" || model.cf) { res.fiscal_code = model.cf; }
    return res;
  }

  toCandidateJson_addToResponse__address(res: any, model: any) {
    if (model.address == "" || model.address) { res.street = model.address; }
    return res;
  }

  toCandidateJson_addToResponse__addressNumber(res: any, model: any) {
    if (model.addressNumber == "" || model.addressNumber) { res.house_number = model.addressNumber; }
    return res;
  }

  toCandidateJson_addToResponse__cap(res: any, model: any) {
    if (model.cap == "" || model.cap) { res.cap = model.cap; }
    return res;
  }

  toCandidateJson_addToResponse__city(res: any, model: any) {
    if (model.city == "" || model.city) { res.city = model.city; }
    return res;
  }

  toCandidateJson_addToResponse__nationality(res: any, model: any) {
    if (model.nation == "" || model.nation) { res.nationality = model.nation; }
    return res;
  }

  toCandidateJson(model: any): any {
    let res = new CensimentoCandidatoResource();
    if (model != null) {
      res = this.toCandidateJson_addToResponse__last_name(res, model);
      res = this.toCandidateJson_addToResponse__first_name(res, model);
      res = this.toCandidateJson_addToResponse__cf(res, model);
      res = this.toCandidateJson_addToResponse__address(res, model);
      res = this.toCandidateJson_addToResponse__addressNumber(res, model);
      res = this.toCandidateJson_addToResponse__cap(res, model);
      res = this.toCandidateJson_addToResponse__city(res, model);
      res = this.toCandidateJson_addToResponse__nationality(res, model);
    }
    return res;
  }





  toNegotiateJson_addToResponse__starting_date_activity(res: any, model: any) {
    if (model.starterDate) { res.starting_date_activity = model.starterDate; }
    return res;
  }

  toNegotiateJson_addToResponse__trial_period(res: any, model: any) {
    if (model.trialPeriod) { res.trial_period = model.trialPeriod; }
    return res;
  }

  toNegotiateJson_addToResponse__ral_agreed(res: any, model: any) {
    if (model.ral) { res.ral_agreed = model.ral.replace(".", ""); }
    return res;
  }

  toNegotiateJson_addToResponse__ticket(res: any, model: any) {
    if (model.ticket[0]) { res.ticket = model.ticket[0].value; }
    return res;
  }

  toNegotiateJson_addToResponse__specifications(res: any, model: any) {
    if (model.specifications) { res.specifications = model.specifications; }
    return res;
  }

  toNegotiateJson_addToResponse__special_conditions(res: any, model: any) {
    if (model.specialConditions) { res.special_conditions = model.specialConditions; }
    return res;
  }

  toNegotiateJson_addToResponse__variable(res: any, model: any) {
    if (model.variables[0]) {
      res.variable = {
        'choosed': model.variables[0].value
      };
    }
    if (model.variablesList != null && model.variablesList.length > 0) {
      let applicant_offer_data = [];
      applicant_offer_data = model.variablesList;
      res.variable.type = applicant_offer_data
    }
    if (model.variablesCondition) { res.variable.negotiated_conditions = model.variablesCondition; }
    if (model.variablesCondition == "") { res.variable.negotiated_conditions = model.variablesCondition; }
    return res;
  }

  toNegotiateJson_addToResponse__documents(res: any, model: any) {
    if (model.documents && model.documents.length > 0) {
      let applicant_offer_data = [];
      for (let d of model.documents) {
        applicant_offer_data.push(d);
      }
      res.documents = {
        'links_document': applicant_offer_data
      };
    } else {
      res.documents = {
        'links_document': []
      };
    }
    return res;
  }

  toNegotiateJson_addToResponse__effective_date_start_activity(res: any, model: any) {
    if (model.effectiveStarterDate) {
      res.effective_date_start_activity = model.effectiveStarterDate;
    }
    return res;
  }

  toNegotiateJson_addToResponse__number_monthly_payments(res: any, model: any) {
    if (model.monthsNumber) {
      res.number_monthly_payments = model.monthsNumber;
    }
    return res;
  }

  toNegotiateJson_addToResponse__rlm(res: any, model: any) {
    if (model.rlm) {
      res.rlm = model.rlm.replaceAll(".", "").replaceAll(" ", "").replaceAll("è¢â€šÂ¬", "");
    }
    return res;
  }

  toNegotiateJson(model: any): any {
    let res = new CensimentoNegoziatoResource();
    if (model != null) {
      res = this.toNegotiateJson_addToResponse__starting_date_activity(res, model);
      res = this.toNegotiateJson_addToResponse__trial_period(res, model);
      res = this.toNegotiateJson_addToResponse__ral_agreed(res, model);
      res = this.toNegotiateJson_addToResponse__ticket(res, model);
      res = this.toNegotiateJson_addToResponse__specifications(res, model);
      res = this.toNegotiateJson_addToResponse__special_conditions(res, model);
      res = this.toNegotiateJson_addToResponse__variable(res, model);
      res = this.toNegotiateJson_addToResponse__documents(res, model);
      res = this.toNegotiateJson_addToResponse__effective_date_start_activity(res, model);
      res = this.toNegotiateJson_addToResponse__number_monthly_payments(res, model);
      res = this.toNegotiateJson_addToResponse__rlm(res, model);
    }
    return res;
  }

  toTransparentJson(model: TransparentModel): any {
    let res: any = {};
    if (model != null) {
      if(model.comparative_selection && model.comparative_selection[0] && model.comparative_selection[0].value) {
        model.comparative_selection = model.comparative_selection[0].value;
      }
      res.comparative_selection = model.comparative_selection;
      if (model.comparative_selection && !Array.isArray(model.comparative_selection)) {
        res.candidates_number = model.candidates_number;
        res.list_candidates = model.list_candidates;
      } else if(model.comparative_selection === false && !Array.isArray(model.comparative_selection)) {
        res.motivation = model.motivation;
      } else {
        res.candidates_number = null;
        res.list_candidates = "";
        res.motivation = "";
        res.comparative_selection = null;
      }
      res.chosen_motivation = model.chosen_motivation;
      res.collaborators = model.collaborators;
      res.collaboratorsCounter = model.collaboratorsCounter;
      if (model.tool_used && model.tool_used.length > 0) {
        res.tool_used = model.tool_used[0];
      } else {
        res.tool_used = null;
      }

    }
    return res;
  }


  toHiringNegotiateJson_addToResponse__starting_date_activity(res: any, model: any) {
    if (model.starterDate) { res.starting_date_activity = model.starterDate; }
    return res;
  }

  toHiringNegotiateJson_addToResponse__trial_period(res: any, model: any) {

    if (model.trialPeriod) { res.trial_period = model.trialPeriod; }
    return res;
  }

  toHiringNegotiateJson_addToResponse__ral_agreed(res: any, model: any) {
    if (model.ral) { res.ral_agreed = model.ral.replace(".", ""); }
    return res;
  }

  toHiringNegotiateJson_addToResponse__ticket(res: any, model: any) {
    if (model.ticket[0]) { res.ticket = model.ticket[0].value; }
    return res;
  }

  toHiringNegotiateJson_addToResponse__specifications(res: any, model: any) {
    if (model.specifications) { res.specifications = model.specifications; }
    return res;
  }

  toHiringNegotiateJson_addToResponse__special_conditions(res: any, model: any) {
    if (model.specialConditions) { res.special_conditions = model.specialConditions; }
    return res;
  }

  toHiringNegotiateJson_addToResponse__variable(res: any, model: any) {
    if (model.variables && model.variables[0]) {
      res.variable = {
        'choosed': model.variables[0].value
      };
    }
    if (model.variablesList != null && model.variablesList.length > 0) {
      let applicant_offer_data = [];
      applicant_offer_data = model.variablesList;
      res.variable.type = applicant_offer_data
    }
    if (model.variablesCondition) { res.variable.negotiated_conditions = model.variablesCondition; }

    return res;
  }

  toHiringNegotiateJson_addToResponse__documents(res, model) {
    if (model.documents && model.documents.length > 0) {
      let applicant_offer_data = [];
      for (let d of model.documents) {
        applicant_offer_data.push(d.url);
      }
      res.documents = {
        'links_document': applicant_offer_data
      };
    } else {
      res.documents = {
        'links_document': []
      };
    }
    return res;
  }

  toHiringNegotiateJson(model: any): any {
    let res = new CensimentoNegoziatoResource();
    if (model != null) {
      res = this.toHiringNegotiateJson_addToResponse__starting_date_activity(res, model);
      res = this.toHiringNegotiateJson_addToResponse__trial_period(res, model);
      res = this.toHiringNegotiateJson_addToResponse__ral_agreed(res, model);
      res = this.toHiringNegotiateJson_addToResponse__ticket(res, model);
      res = this.toHiringNegotiateJson_addToResponse__specifications(res, model);
      res = this.toHiringNegotiateJson_addToResponse__special_conditions(res, model);
      res = this.toHiringNegotiateJson_addToResponse__variable(res, model);
      res = this.toHiringNegotiateJson_addToResponse__documents(res, model);
    }
    return res;
  }
}
/**
 * HiringSerializer
 */
export class HiringSerializer implements Serializer {

  assignmentSerializer: AssignmentSerializer;
  employeeSerializer: EmployeeSerializer;
  applicantOfferDataSerializer: ApplicantOfferDataSerializer;

  constructor() {
    this.applicantOfferDataSerializer = new ApplicantOfferDataSerializer();
    this.assignmentSerializer = new AssignmentSerializer();
    this.employeeSerializer = new EmployeeSerializer();
  }



  fromJson_addToResponse__requestReason(res, json) {
    if (res.requestReason) { res.requestReason = json.requestReason; }
    return res;
  }

  fromJson_addToResponse__assignment(res, json) {
    if (res.assignment) { res.assignment = this.assignmentSerializer.fromJson(json.assignment); }
    return res;
  }

  fromJson_addToResponse__resumeForm(res, json) {
    if (res.resumeForm) { res.resumeForm = json.resumeForm; }
    return res;
  }

  fromJson_addToResponse__ceasedEmployee(res, json) {
    if (res.ceasedEmployee) { res.ceasedEmployee = json.ceasedEmployee; }
    return res;
  }

  fromJson_addToResponse__ceasedDate(res, json) {
    if (res.ceasedDate) { res.ceasedDate = json.ceasedDate; }
    return res;
  }

  fromJson_addToResponse__requestReasonOther(res, json) {
    if (res.requestReasonOther) { res.requestReasonOther = json.requestReasonOther; }
    return res;
  }

  fromJson_addToResponse__budget(res, json) {
    if (res.budget) { res.budget = json.budget; }
    return res;
  }

  fromJson_addToResponse__task(res, json) {
    if (res.task) { res.task = json.task; }
    return res;
  }

  fromJson_addToResponse__employee(res, json) {
    if (res.employee) { res.employee = this.employeeSerializer.fromJson(json.employee); }
    return res;
  }

  fromJson_addToResponse__venue(res, json) {
    if (res.venue) { res.venue = json.venue; }
    return res;
  }

  fromJson_addToResponse__title(res, json) {
    if (res.title) { res.title = json.title; }
    return res;
  }

  fromJson_addToResponse__worker(res, json) {
    if (res.worker) { res.worker = json.worker; }
    return res;
  }

  fromJson_addToResponse__intermediate(res, json) {
    if (res.intermediate) { res.intermediate = json.intermediate; }
    return res;
  }

  fromJson_addToResponse__officeWorker(res, json) {
    if (res.officeWorker) { res.officeWorker = json.officeWorker; }
    return res;
  }

  fromJson_addToResponse__squareWorker(res, json) {
    if (res.squareWorker) { res.squareWorker = json.squareWorker; }
    return res;
  }

  fromJson_addToResponse__contractType(res, json) {
    if (res.contractType) { res.contractType = json.contractType; }
    return res;
  }

  fromJson_addToResponse__subordinateContract(res, json) {
    if (res.subordinateContract) { res.subordinateContract = json.subordinateContract; }
    return res;
  }

  fromJson_addToResponse__apprenticeship(res, json) {
    if (res.apprenticeship) { res.apprenticeship = json.apprenticeship; }
    return res;
  }

  fromJson_addToResponse__administeredContract(res, json) {
    if (res.administeredContract) { res.administeredContract = json.administeredContract; }
    return res;
  }

  fromJson_addToResponse__StageContract(res, json) {
    if (res.StageContract) { res.StageContract = json.StageContract; }
    return res;
  }

  fromJson_addToResponse__StageContractDuration(res, json) {
    if (res.StageContractDuration) { res.StageContractDuration = json.StageContractDuration; }
    return res;
  }

  fromJson_addToResponse__partTime(res, json) {
    if (res.partTime) { res.partTime = json.partTime; }
    return res;
  }

  fromJson_addToResponse__partTimeHours(res, json) {
    if (res.partTimeHours) { res.partTimeHours = json.partTimeHours; }
    return res;
  }

  fromJson_addToResponse__shiftWorker(res, json) {
    if (res.shiftWorker) { res.shiftWorker = json.shiftWorker; }
    return res;
  }

  fromJson_addToResponse__cdcType(res, json) {
    if (res.cdcType) { res.cdcType = json.cdcType; }
    return res;
  }

  fromJson_addToResponse__cdc(res, json) {
    if (res.cdc) { res.cdc = json.cdc; }
    return res;
  }

  fromJson_addToResponse__ral(res, json) {
    if (res.ral) { res.ral = json.ral; }
    return res;
  }

  fromJson_addToResponse__applicantOffer(res, json) {
    if (res.applicantOffer) { res.applicantOffer = json.applicantOffer; }
    return res;
  }

  fromJson_addToResponse__applicantOfferData(res, json) {
    if (res.applicantOfferData) {
      res.applicantOfferData = this.applicantOfferDataSerializer;
      this.fromJson(json.applicantOfferData);
    }
    return res;
  }

  fromJson_addToResponse__applicantOfferattachment(res, json) {
    if (res.applicantOfferattachment) { res.applicantOfferattachment = json.applicantOfferattachment; }
    return res;
  }

  fromJson_addToResponse__activitiesBeginningDate(res, json) {
    if (res.activitiesBeginningDate) { res.activitiesBeginningDate = json.activitiesBeginningDate; }
    return res;
  }

  fromJson_addToResponse__car(res, json) {
    if (res.car) { res.car = json.car; }
    return res;
  }

  fromJson_addToResponse__carList(res, json) {
    if (res.carList) { res.carList = json.carList; }
    return res;
  }

  fromJson_addToResponse__telepass(res, json) {
    if (res.telepass) { res.telepass = json.telepass; }
    return res;
  }

  fromJson_addToResponse__smartPhone(res, json) {
    if (res.smartPhone) { res.smartPhone = json.smartPhone; }
    return res;
  }

  fromJson(json: any): HiringResource {
    let res = null;
    if (json != null) {
      res = new HiringResource();
      res = this.fromJson_addToResponse__requestReason(res, json);
      res = this.fromJson_addToResponse__assignment(res, json);
      res = this.fromJson_addToResponse__resumeForm(res, json);
      res = this.fromJson_addToResponse__ceasedEmployee(res, json);
      res = this.fromJson_addToResponse__ceasedDate(res, json);
      res = this.fromJson_addToResponse__requestReasonOther(res, json);
      res = this.fromJson_addToResponse__budget(res, json);
      res = this.fromJson_addToResponse__task(res, json);
      res = this.fromJson_addToResponse__employee(res, json);
      res = this.fromJson_addToResponse__venue(res, json);
      res = this.fromJson_addToResponse__title(res, json);
      res = this.fromJson_addToResponse__worker(res, json);
      res = this.fromJson_addToResponse__intermediate(res, json);
      res = this.fromJson_addToResponse__officeWorker(res, json);
      res = this.fromJson_addToResponse__squareWorker(res, json);
      res = this.fromJson_addToResponse__contractType(res, json);
      res = this.fromJson_addToResponse__subordinateContract(res, json);
      res = this.fromJson_addToResponse__apprenticeship(res, json);
      res = this.fromJson_addToResponse__administeredContract(res, json);
      res = this.fromJson_addToResponse__StageContract(res, json);
      res = this.fromJson_addToResponse__StageContractDuration(res, json);
      res = this.fromJson_addToResponse__partTime(res, json);
      res = this.fromJson_addToResponse__partTimeHours(res, json);
      res = this.fromJson_addToResponse__shiftWorker(res, json);
      res = this.fromJson_addToResponse__cdcType(res, json);
      res = this.fromJson_addToResponse__cdc(res, json);
      res = this.fromJson_addToResponse__ral(res, json);
      res = this.fromJson_addToResponse__applicantOffer(res, json);
      res = this.fromJson_addToResponse__applicantOfferData(res, json);
      res = this.fromJson_addToResponse__applicantOfferattachment(res, json);
      res = this.fromJson_addToResponse__activitiesBeginningDate(res, json);
      res = this.fromJson_addToResponse__car(res, json);
      res = this.fromJson_addToResponse__carList(res, json);
      res = this.fromJson_addToResponse__telepass(res, json);
      res = this.fromJson_addToResponse__smartPhone(res, json);
    }
    return res;
  }







  description_profile_addToResponse__job(res: any, model: any) {
    if (model.job) {
      res.description_profile = {
        'job': model.job
      }
    }
    return res;
  }

  description_profile_addToResponse__refer_to(res: any, model: any) {
    if (model.displayName || model.role) { res.description_profile.refer_to = this.employeeSerializer.toJson(model); }
    return res;
  }

  description_profile_addToResponse__head_office(res: any, model: any) {
    if (model.head_office) { res.description_profile.head_office = model.head_office; }
    return res;
  }

  description_profile_addToResponse__hypothesized_qualification(res: any, model: any) {
    if (model.hypothesized_qualification) {
      res.description_profile.hypothesized_qualification = {
        'qualification': model.hypothesized_qualification[0]
      };
    }
    if (model.worker.length > 0) {
      res.description_profile.hypothesized_qualification.qualification_level = model.worker[0];
    }
    if (model.intermediate.length > 0) {
      res.description_profile.hypothesized_qualification.qualification_level = model.intermediate[0];
    }
    if (model.officeWorker.length > 0) {
      res.description_profile.hypothesized_qualification.qualification_level = model.officeWorker[0];
    }
    if (model.squareWorker.length > 0) {
      res.description_profile.hypothesized_qualification.qualification_level = model.squareWorker[0];
    }
    return res;
  }

  description_profile_addToResponse__hypothesized_contract(res: any, model: any) {
    if (model.hypothesized_contract) {
      res.description_profile.hypothesized_contract = {
        'typology': model.hypothesized_contract[0]
      };
    }
    if (model.subordinateContract) {
      if (model.subordinateContract.length > 0) {
        let subordinated_typologyLevel = res.description_profile.hypothesized_contract.typology;
        subordinated_typologyLevel.subordinated_typology = model.subordinateContract[0];
        res.description_profile.hypothesized_contract.typology = subordinated_typologyLevel;
      };
    }
    if (model.apprenticeshipDuration) {
      let subordinated_typologyLevel = res.description_profile.hypothesized_contract.typology;
      subordinated_typologyLevel.duration = model.apprenticeshipDuration;
      res.description_profile.hypothesized_contract.typology = subordinated_typologyLevel;
    }
    if (model.administeredContract) {
      let subordinated_typologyLevel = res.description_profile.hypothesized_contract.typology;
      subordinated_typologyLevel.number_of_month = model.administeredContract;
      res.description_profile.hypothesized_contract.typology = subordinated_typologyLevel;
    }
    if (model.stageContract) {
      if (model.stageContract.length > 0) {
        let subordinated_typologyLevel = res.description_profile.hypothesized_contract.typology;
        subordinated_typologyLevel.subordinated_typology = model.stageContract[0];
        res.description_profile.hypothesized_contract.typology = subordinated_typologyLevel;
      };
    }
    if (model.stageContractDuration) {
      let subordinated_typologyLevel = res.description_profile.hypothesized_contract.typology;
      subordinated_typologyLevel.duration = model.stageContractDuration;
      res.description_profile.hypothesized_contract.typology = subordinated_typologyLevel;
    }
    return res;
  }

  description_profile_addToResponse__part_time(res: any, model: any) {
    if (model.partTime[0]) {
      res.description_profile.part_time = {
        'choosed': model.partTime[0].value
      };
    }
    if (model.partTimeHours) {
      let partTimeHours = res.description_profile.part_time;
      partTimeHours.time_day_work = model.partTimeHours;
      res.description_profile.part_time = partTimeHours;
    }
    return res;
  }

  description_profile_addToResponse__shift_worker(res: any, model: any) {
    if (model.shiftWorker && model.shiftWorker.length > 0) {
      res.description_profile.shift_worker = model.shiftWorker[0].value;
    }
    return res;
  }

  description_profile_addToResponse__cost_type(res: any, model: any) {
    if (model.cdcType) {
      res.description_profile.cost_type = model.cdcType[0];
    }
    return res;
  }

  description_profile_addToResponse__cdc(res: any, model: any) {
    if (model.cdc) {
      res.description_profile.cdc = model.cdc;
    }
    return res;
  }

  description_profile_addToResponse__ral(res: any, model: any) {
    if (model.ral) {
      res.description_profile.ral = model.ral;
    }
    return res;
  }


  description_profile_addToResponse__assumed_start_date(res: any, model: any) {
    if (model.activitiesBeginningDate) {
      res.description_profile.assumed_start_date = model.activitiesBeginningDate;
    }
    return res;
  }


  toJson_addToResponse__description_profile(res: any, model: any) {
    res = this.description_profile_addToResponse__job(res, model);
    res = this.description_profile_addToResponse__refer_to(res, model);
    res = this.description_profile_addToResponse__head_office(res, model);
    res = this.description_profile_addToResponse__hypothesized_qualification(res, model);
    res = this.description_profile_addToResponse__hypothesized_contract(res, model);
    res = this.description_profile_addToResponse__part_time(res, model);
    res = this.description_profile_addToResponse__shift_worker(res, model);
    res = this.description_profile_addToResponse__cost_type(res, model);
    res = this.description_profile_addToResponse__cdc(res, model);
    res = this.description_profile_addToResponse__ral(res, model);
    res = this.description_profile_addToResponse__assumed_start_date(res, model);
    return res;
  }

  toJson_addToResponse__offer(res: any, model: any) {
    if (model.applicantOffer[0]) {
      res.offer = {
        'candidate_offer': {
          'choosed': model.applicantOffer[0].value
        }
      };
    }
    if (model.applicantOfferData) {
      res.offer.candidate = {
        'name': model.applicantOfferData
      };
    }
    if (model.applicantOfferattachment) {
      res.offer.link_cv = model.applicantOfferattachment;
    }
    return res;
  }

  toJson_addToResponse__equipment(res: any, model: any) {
    if (model.car[0]) {
      res.equipment = {
        'car': {
          'choosed': model.car[0].value
        }
      };
    }
    if (model.carList.length > 0) {
      res.equipment.car.type = model.carList[0];
    }
    if (model.telepass) {
      if (model.telepass[0]) {
        res.equipment.telepass = model.telepass[0].value;
      }
    } else {
      res.equipment.telepass = false;
    }
    if (model.smartPhone[0]) {
      res.equipment.smartPhone = model.smartPhone[0].value;
    }

    return res;
  }


  toJson_addToResponse__request_reason(res: any, model: any) {
    if (model.request_reason) { res.request_reason = model.request_reason[0]; }
    return res;
  }

  toJson_addToResponse__commessa(res: any, model: any) {
    if (model.number || model.subject) { res.commessa = this.assignmentSerializer.toJson(model); }
    return res;
  }

  toJson_addToResponse__summary_sheet(res: any, model: any) {
    if (model.summary_sheet) {
      res.summary_sheet = {
        'sds_number_or_link': model.summary_sheet
      };
    }
    return res;
  }

  toJson_addToResponse__employee_breakoff(res: any, model: any) {
    if (model.employee_breakoff) {
      res.employee_breakoff = {
        'name': model.employee_breakoff
      };
    }
    return res;
  }

  toJson_addToResponse__employee_date_breakoff(res: any, model: any) {
    if (model.employee_date_breakoff) { res.employee_date_breakoff = model.employee_date_breakoff; }
    return res;
  }

  toJson_addToResponse__other(res: any, model: any) {
    if (model.other) { res.other = model.other; }
    return res;
  }

  toJson_addToResponse__expect_budget(res: any, model: any) {
    if (model.expect_budget) { res.expect_budget = model.expect_budget[0].value; }
    return res;
  }


  toJson(model: any): any {
    let res = new HiringResource();
    if (model != null) {
      res = this.toJson_addToResponse__request_reason(res, model);
      res = this.toJson_addToResponse__commessa(res, model);
      res = this.toJson_addToResponse__summary_sheet(res, model);
      res = this.toJson_addToResponse__employee_breakoff(res, model);
      res = this.toJson_addToResponse__employee_date_breakoff(res, model);
      res = this.toJson_addToResponse__other(res, model);
      res = this.toJson_addToResponse__expect_budget(res, model);
      res = this.toJson_addToResponse__description_profile(res, model);
      res = this.toJson_addToResponse__offer(res, model);
      res = this.toJson_addToResponse__equipment(res, model);
    }
    return res;
  }
}

/**
 * AssignmentSerializer
 */
export class AssignmentSerializer implements Serializer {
  fromJson(json: any): AssignmentResource {
    let res = null;
    if (json != null) {
      res = new AssignmentResource();
      res.number = json.number;
      res.subject = json.subject;
    }
    return res;
  }
  toJson(model: AssignmentResource): any {
    let res = null;
    if (model != null) {
      res = new AssignmentResource();
      res.numberOrSubject = model.numberOrSubject;
    }
    return res;
  }
}

/**
 * EmployeeSerializer
 */
export class EmployeeSerializer implements Serializer {

  fromJson(json: any): EmployeeResource {
    let res = null;
    if (json != null) {
      res = new EmployeeResource();
      res.role = json.role;
      res.firstName = json.firstName;
      res.lastName = json.lastName;
    }
    return res;
  }
  toJson(model: EmployeeResource): any {
    let res = null;
    if (model != null) {
      res = new EmployeeResource();
      res.role = model.role;
      res.name = model.displayName;
    }
    return res;
  }

  toJsonEmpty(model: any): EmployeeResource {
    let res = null;
    res = new EmployeeResource();
    res.role = "";
    res.name = "";
    return res;
  }
}

/**
 * ApplicantOfferDataSerializer
 */
export class ApplicantOfferDataSerializer implements Serializer {
  fromJson(json: any): ApplicantOfferDataResource {
    let res = null;
    if (json != null) {
      res = new ApplicantOfferDataResource();
      res.firstName = json.firstName;
      res.lastName = json.lastName;
    }
    return res;
  }
  toJson(model: ApplicantOfferDataResource): any {
    let res = null;
    if (model != null) {
      model = new ApplicantOfferDataResource();
      res.firstName = model.firstName;
      res.lastName = model.lastName;
    }
    return res;
  }
}