import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, LocationStrategy, PathLocationStrategy, CurrencyPipe, registerLocaleData} from '@angular/common';
import { NgModule, LOCALE_ID} from '@angular/core';
import localeIt from '@angular/common/locales/it';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule,NgbActiveModal,NgbModalModule  } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { TokenInterceptor } from '../app/core/service/auth/token.interceptor';
import { JwtModule } from '@auth0/angular-jwt';
import { CalendarModule } from 'angular-calendar';
import { DragulaModule } from 'ng2-dragula';
import { QuillModule } from 'ngx-quill';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NotifierModule } from 'angular-notifier';
import { BlockUIModule } from 'ng-block-ui';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ErrorInterceptor } from './core/service/auth/error.interceptor';
import { ActionModalComponent } from './approvazioni/approvazioni-action/action.component';
import { ApplicationDataModule } from './richieste/applicant-data/applicant-data.module';
import { SendRaasConfirmComponent } from './assunzioni/resource/assunzioni.confirmsend.resource';
import { environment } from 'src/environments/environment';
import { RichiesteActionModule } from './richieste/richieste-action/richieste-action.module';




// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20,
};

export function tokenGetter() {
  return localStorage.getItem('token');
}
registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    ActionModalComponent,
    SendRaasConfirmComponent
  ],
  imports: [
    CommonModule ,  // CommonModule because the module's component needs common directives.
    FormsModule,
    NotifierModule,
    ReactiveFormsModule,
    NgbModalModule,
    CalendarModule.forRoot(),
    QuillModule.forRoot(),
    DragulaModule.forRoot(),
    RouterModule.forRoot(Approutes, { useHash: false }),
    NgMultiSelectDropDownModule.forRoot(),
    AngularMultiSelectModule,
    BrowserModule,
    ApplicationDataModule,
    BrowserAnimationsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BlockUIModule.forRoot(),
    NgbModule,
    SlimLoadingBarModule.forRoot(),
    RouterModule.forRoot(Approutes, { useHash: false , enableTracing: false}),
    PerfectScrollbarModule,
    AgmCoreModule.forRoot({apiKey: environment.google.mapkey,language:'it'}),
    ToastrModule.forRoot(),
    RichiesteActionModule
  ],
  entryComponents: [ 
    ActionModalComponent,
    SendRaasConfirmComponent
  ],
  providers: [
    CurrencyPipe,
    NgbActiveModal,
    { 
      provide: LOCALE_ID, 
      useValue: "it" 
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy, // Provider per gli url applicativi ( HashLocationStrategy : aggiunge un # nell'url)
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }, // Token interceptor per aggiungere token
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true } // loader interceptor
  ],
  exports: [TranslateModule,RichiesteActionModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
