import { Directive, ElementRef, EventEmitter, OnInit, Output } from "@angular/core";
import { NgModel } from "@angular/forms";


@Directive({
    selector: '[googleinputplaces]',
    providers: [NgModel]
  })
  export class GoogleInputPlacesDirective implements OnInit{

    @Output() changePlace = new EventEmitter<string>();
    private el:HTMLInputElement;
    autocompleteInput:any;
    
    constructor(
        public model:NgModel,
        el:ElementRef){
        this.el = el.nativeElement;
    }
    ngOnInit(): void {
       this.autocompleteInput = new google.maps.places.Autocomplete(this.el,{});
        google.maps.event.addListener(this.autocompleteInput, 'place_changed', () => {
            const place = this.autocompleteInput.getPlace();
            let value = "";

            if(place.formatted_address){
              value = place.formatted_address;
            }else if(place.vicinity){
              value = place.vicinity;
            }else{
              value = place.address_components[0].long_name;
            }
            if(this.isEstablishment(place.types)){
              value = place.name+", "+value;
            }

            this.el.value = value;
            this.changePlace.next(value);
        });
    }

    isEstablishment(types:string[] = []):boolean{
      //types con establishment  indica che è presente un risultato aziendale
      let res:boolean = false;
      if(types.includes("establishment")){
        res = true;
      }
      return res;
    } 

  }