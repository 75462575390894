import { Component, ViewEncapsulation, Input, OnInit } from '@angular/core';
import { BeneficiaryResource } from 'src/app/richieste/applicant-data/resource/beneficiary.resource';
import { ApprovalService } from '../../../approvazioni/service/approval.service';

@Component({
  selector: 'app-beneficiary-detail',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './beneficiary-detail.html',
  styleUrls : ['./beneficiary-detail.css']
})
export class BeneficiaryDetailComponent implements OnInit {
  @Input() public beneficiary: BeneficiaryResource;
  isMultiBeneficiary: boolean = false;
  constructor(private _approvalService: ApprovalService ) {}

  ngOnInit(): void {
    console.log(this.beneficiary)
    if(Array.isArray(this.beneficiary)) {
      this.isMultiBeneficiary = true;
    } else {
      this.isMultiBeneficiary = false;
    }
  }


  renderUser(): any {
    return this._approvalService.renderUser(this.beneficiary);
  }

  renderSingleUser(item): any {
    return this._approvalService.renderUser(item);
  }
}

