import { NgModule } from "@angular/core";
import { ActionRichiesteModalComponent } from "./richieste-action.component";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModalModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { CalendarModule } from "angular-calendar";
import { BlockUIModule } from "ng-block-ui";
import { QuillModule } from "ngx-quill";
import { DragulaModule } from "ng2-dragula";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { TranslateModule } from "@ngx-translate/core";
import { SubmitterDetailModule } from "../submitter-detail/submitter-detail.module";
import { ApplicationDataModule } from "../applicant-data/applicant-data.module";

@NgModule({
    declarations: [
        ActionRichiesteModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NgbModalModule,
        ToastrModule.forRoot({
        maxOpened : 10,
        preventDuplicates: true,
        timeOut: 10000,
        positionClass: 'toast-bottom-right',
        }),
        CalendarModule.forRoot(),
        BlockUIModule.forRoot(),
        QuillModule.forRoot(),
        DragulaModule.forRoot(),
        PerfectScrollbarModule,
        NgMultiSelectDropDownModule,
        AngularMultiSelectModule,
        TranslateModule,
        ApplicationDataModule,
        SubmitterDetailModule
    ],
    exports: [
        ActionRichiesteModalComponent
      ]
    })
export class RichiesteActionModule { }